'use client';

import { Menu } from '@/lib/model';
import { SideMenuWrapper } from '../../../side-menu';
import { MobileSideMenuWrapper } from '../../../side-menu/mobile';
import { useHeaderSideMenuStete } from './MenuStateProvider';

interface Props {
  menu: Menu;
  sideMenu: Menu;
}

export const MenuSidebar = ({ menu, sideMenu }: Props) => {
  const { isMenuOpen, onMenuClick } = useHeaderSideMenuStete();
  return (
    <>
      {/* Opening/Closing desktop side menu*/}
      {isMenuOpen && (
        <SideMenuWrapper menu={sideMenu || menu} onCloseMenu={onMenuClick} />
      )}
      {/* Opening/Closing mobile menu*/}
      {isMenuOpen && (
        <MobileSideMenuWrapper
          onCloseMenu={onMenuClick}
          menu={sideMenu || menu}
        />
      )}
    </>
  );
};

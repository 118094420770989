import clsx from 'clsx';
import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@/app/components/icons';
import { useSetChannel } from '@/hooks/useSetChannel';
import { useChannelList, useGetChannelPref } from '@/providers/ChannelProvider';
import { extractPathsFromAllPath, generateChannelPath } from '@/utils';

export function LanguageSelectionMobileWrapper() {
  const channelPref = useGetChannelPref();

  const { groupedChannels } = useChannelList();
  const { firstPathHalfName, secondPath } = extractPathsFromAllPath(
    generateChannelPath(channelPref),
  );
  const [isLanguageSelectionOpen, setIsLanguageSelectionOpen] = useState(false);
  const setChannel = useSetChannel();
  const selectedChannel = groupedChannels?.find(
    x => x.languageCode === firstPathHalfName,
  );

  if (!selectedChannel?.children) {
    return null;
  }
  return (
    <div
      className={clsx(
        'flex-row rounded items-center justify-center cursor-pointer sm:hidden flex  relative overflow-hidden',
      )}
    >
      {!isLanguageSelectionOpen && selectedChannel && (
        <div
          onClick={() => setIsLanguageSelectionOpen(true)}
          className={clsx('border border-grey-8 flex py-1.5 pl-2 pr-1 rounded')}
        >
          <span className="text-grey-6 font-sf text-sm font-medium uppercase leading-4 ">
            {secondPath}
          </span>
          <ChevronRightIcon
            width={16}
            height={16}
            color={'currentColor'}
            className=" group-hover:text-grey-13 text-grey-6"
          />
        </div>
      )}
      {isLanguageSelectionOpen && selectedChannel && (
        <>
          <div className="flex flex-row gap-2 items-center py-1.5 relative opacity-80 overflow-x-scroll scrollbar-none">
            {selectedChannel?.children?.map((channel, index) => (
              <button
                key={index}
                className="text-grey-1 font-sf text-xs font-bold"
                onClick={() => setChannel(channel)}
              >
                {channel.name}
              </button>
            ))}
          </div>
          <div
            style={{
              background:
                'linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0.00) 100%)',
            }}
            className="absolute end-0 w-24 h-full flex justify-end items-center pointer-events-none"
          >
            <ChevronLeftIcon
              onClick={() => setIsLanguageSelectionOpen(false)}
              color="#C2C3C3"
              className="z-10 pointer-events-auto"
            />
          </div>
        </>
      )}
    </div>
  );
}

'use client';

import clsx from 'clsx';
import Link from 'next/link';
import React, { ComponentProps } from 'react';
import { PageTitleWrapper } from './PageTitleWrapper';
import { Channel, Menu, MenuItem } from '@/lib/model';
import { extractPathsFromAllPath, generateChannelPath } from '@/utils/channels';
import { useSetChannel } from '@/hooks/useSetChannel';
import { useChannelList, useGetChannelPref } from '@/providers/ChannelProvider';
import { SearchIcon } from '@/app/components/icons';
import { MenuItemLink } from '../../menu/MenuItemLink';
import { FEATURE_FLAG } from '@/feaureflag';
import { LocaleUppercase } from '@/app/components/text/LocaleUppercase';
import { cn } from '@/lib/utils';
import { useHeaderSideMenuStete } from './MenuStateProvider';

interface Props extends ComponentProps<typeof PageTitleWrapper> {
  menu?: Menu;
  iconColor?: string;
  languageSelectionWrapperClassName?: string;
}

export function HeaderMenuWrapper({
  pageTitle,
  titleClassName,
  parentTitle,
  menu,
  iconColor,
  languageSelectionWrapperClassName,
}: Props) {
  const setChannel = useSetChannel();
  const channelPref = useGetChannelPref();
  const { groupedChannels } = useChannelList();
  const channelPath = generateChannelPath(channelPref);
  const { firstPathHalfName } = extractPathsFromAllPath(channelPath);
  const selectedChannelFromList = groupedChannels?.find(
    x => x.languageCode === firstPathHalfName,
  );
  const { isLanguageSelectionOpen, setIsLanguageSelectionOpen } =
    useHeaderSideMenuStete();
  const primaryMenuItems = menu?.primaryMenuItems?.map(
    item => item as MenuItem,
  );
  const liveMenu = menu?.liveMenu;
  const liveMenuItems = liveMenu?.menuItems?.map(item => item as MenuItem);

  const onSelectChannel = (channel: Channel) => {
    setChannel(channel);
    setIsLanguageSelectionOpen(false);
  };

  return isLanguageSelectionOpen ? (
    // If language selection open, we will show multiple languages if we have, else we will show all menu items
    <div className="flex flex-row sm:ms-10 ms-8 overflow-x-auto scrollbar-none text-nowrap">
      {selectedChannelFromList?.children?.map((channel, index) => (
        <button
          key={index}
          className={cn(
            'text-grey-12 p-3 flex items-center justify-center font-sf text-sm font-bold uppercase leading-4 cursor-pointer',
            languageSelectionWrapperClassName,
          )}
          onClick={() => onSelectChannel(channel)}
        >
          {channel.name}
        </button>
      ))}
    </div>
  ) : (
    <>
      <div
        className={clsx(
          'flex sm:gap-6 gap-4 text-nowrap xl:mx-8 mx-4 items-center w-full',
          {
            'justify-center': pageTitle,
            'lg:justify-center justify-start overflow-x-auto scrollbar-none':
              !pageTitle,
          },
        )}
      >
        {pageTitle ? (
          <PageTitleWrapper
            pageTitle={pageTitle}
            titleClassName={titleClassName}
            parentTitle={parentTitle}
          />
        ) : (
          <>
            {primaryMenuItems?.map(menu => (
              <MenuItemLink menu={menu} key={menu.id}>
                <span
                  id="primary-menu-item"
                  className="text-grey-12 font-notosans text-sm font-bold leading-4"
                >
                  <LocaleUppercase>{menu.name}</LocaleUppercase>
                </span>
              </MenuItemLink>
            ))}
            {liveMenu?.active && (
              <>
                <div className="min-w-[1px] h-4 bg-grey-6" />

                <div className="flex flex-row gap-1.5 items-center">
                  <div className="rounded-full w-1.5 h-1.5 bg-[#FF3F3F]" />
                  <div
                    className={clsx(
                      'text-[#FF3F3F] font-bold font-sf text-sm leading-4',
                    )}
                  >
                    <LocaleUppercase>{liveMenu.label}</LocaleUppercase>
                  </div>
                </div>
              </>
            )}
            {liveMenu?.active &&
              liveMenuItems?.map(menu => (
                <MenuItemLink menu={menu} key={menu.id}>
                  <span className="flex flex-row gap-1.5 items-center text-grey-12 font-sf text-sm leading-4">
                    <LocaleUppercase>{menu.name}</LocaleUppercase>
                  </span>
                </MenuItemLink>
              ))}
          </>
        )}
      </div>
      {FEATURE_FLAG.aiSearch ? (
        <Link
          dir="inherit"
          href="/ai-search"
          className={clsx(
            'cursor-pointer 2xl:end-[57px] end-5 sm:flex hidden items-center justify-center lg:absolute p-2',
            {
              absolute: pageTitle,
            },
          )}
        >
          <SearchIcon className={iconColor ?? 'text-grey-11'} />
        </Link>
      ) : null}
    </>
  );
}

import clsx from 'clsx';
import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@/app/components/icons';
import { useChannelList, useGetChannelPref } from '@/providers/ChannelProvider';
import { extractPathsFromAllPath, generateChannelPath } from '@/utils';

/* Open language selection on header, we will show here if we have multiple language on the selected channel */

type Props = {
  isLanguageSelectionOpen: boolean;
  setIsLanguageSelectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMainLogoInView: boolean;
};

export function LanguageSelectionWrapper({
  isLanguageSelectionOpen,
  setIsLanguageSelectionOpen,
  isMainLogoInView,
}: Props) {
  const channelPref = useGetChannelPref();
  const { groupedChannels } = useChannelList();
  const { secondPath, firstPathHalfName } = extractPathsFromAllPath(
    generateChannelPath(channelPref),
  );
  const selectedChannel = groupedChannels?.find(
    x => x.languageCode === firstPathHalfName,
  );
  if (!selectedChannel?.children) {
    return null;
  }

  return (
    <div
      onClick={() => setIsLanguageSelectionOpen(open => !open)}
      className={clsx(
        'ms-2 pl-3 py-1.5 pe-2 gap-1 flex-row rounded border border-grey-8 hover:bg-grey-1 items-center justify-center cursor-pointer group transition-all ease-out duration-120 sm:flex hidden z-10',
        {
          'bg-grey-1': isLanguageSelectionOpen,
          'me-[35px]': isMainLogoInView && !secondPath,
        },
      )}
    >
      <div
        className={clsx(
          'text-grey-6 font-sf group-hover:text-grey-13 text-sm font-medium uppercase leading-4',
          {
            '!text-grey-13': isLanguageSelectionOpen,
          },
        )}
      >
        {secondPath}
      </div>
      {isLanguageSelectionOpen ? (
        <ChevronLeftIcon color="#0C0E0E" />
      ) : (
        <ChevronRightIcon
          width={16}
          height={16}
          color={'currentColor'}
          className=" group-hover:text-grey-13 text-grey-6"
        />
      )}
    </div>
  );
}

'use client';

import { isRTL } from '@/i18n/utils';
import { useLocale } from 'next-intl';
import { useCallback, useEffect, useRef } from 'react';
import { useHeaderSideMenuStete } from '../../header/main-header-sidebar-wrapper/components/MenuStateProvider';

interface Props {
  parentId: string;

  noAnimation?: boolean;
  showFullLogo?: boolean;
}

export function ChannelLogoAnimation({
  noAnimation,
  showFullLogo,
  parentId,
}: Props) {
  const { isVisible } = useHeaderSideMenuStete();
  const ref = useRef<SVGAElement>();

  const locale = useLocale();

  /**
   * This function display channel full name with TRT or just channel name based on
   * header position is sticky or not
   */
  const setChannelParts = useCallback(
    (svgElement: SVGAElement, isVisible: boolean, locale: string) => {
      if (!svgElement.style) return;
      const selector = 'g[data-name="trt"]';
      const elTransform = isRTL(locale) ? 35 : -35;
      const gTransform = isRTL(locale) ? 75 : -75;

      if (noAnimation && showFullLogo) {
        (
          svgElement.querySelectorAll(selector) as unknown as Array<SVGAElement>
        ).forEach(el => {
          el.style.opacity = '1';
        });
      } else if (noAnimation && !showFullLogo) {
        svgElement.style.translate = `${elTransform}px`;
        svgElement.style.height = `100%`;

        (
          svgElement.querySelectorAll(selector) as unknown as Array<SVGAElement>
        ).forEach(el => {
          el.style.transitionProperty = 'transform translate scale opacity';
          el.style.transformOrigin = 'center';
          el.style.opacity = '0';
        });
      }

      if (noAnimation) return;
      svgElement.style.transitionProperty = 'transform opacity';
      svgElement.style.transitionDuration = '0.3s';
      /**
       * All Channel logo SVG should have same structure so we can grab the TRT
       * part of logo and animate it, right all TRT part is within <g> tag
       * For the World logo it has different structure and TRT part is inside rect
       * TODO: Make this logic robust
       */

      // const elScale = scale || 1;
      if (isVisible) {
        svgElement.style.translate = `${elTransform}px`;

        (
          svgElement.querySelectorAll(selector) as unknown as Array<SVGAElement>
        ).forEach(el => {
          el.style.transitionProperty = 'transform translate opacity';
          el.style.transformOrigin = 'center';
          el.style.transitionDuration = '0.3s';
          el.style.opacity = '0';
          el.style.translate = `${gTransform}px`;
        });
      } else {
        svgElement.style.translate = '0';
        (
          svgElement.querySelectorAll(selector) as unknown as Array<SVGAElement>
        ).forEach(el => {
          el.style.transitionProperty = 'transform translate opacity';
          el.style.transitionDuration = '0.3s';
          el.style.opacity = '1';
          el.style.translate = '0';
        });
      }
    },
    [noAnimation, showFullLogo],
  );

  useEffect(() => {
    if (!ref.current) {
      ref.current = document.querySelector(`[data-id="${parentId}"]`)
        ?.firstChild as SVGAElement;
    }
    if (ref.current) {
      setChannelParts(ref.current, isVisible, locale);
    }
  }, [isVisible, locale, setChannelParts, parentId]);

  return null;
}

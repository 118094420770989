'use client';

import { ComponentProps, ReactNode } from 'react';
import { CloseIcon, HamburgerMenuIcon } from '@/app/components/icons';
import { useGetChannelPref } from '@/providers/ChannelProvider';
import { PageTitleWrapper } from './PageTitleWrapper';
import { extractPathsFromAllPath, generateChannelPath } from '@/utils';
import { LanguageSelectionWrapper } from './LanguageSelectionWrapper';
import { LanguageSelectionMobileWrapper } from './LanguageSelectionMobileWrapper';
import { cn } from '@/lib/utils';
import { useDeviceType } from '@/hooks/useIsDesktop';
import { LinkWithChannelLink } from '../../../link/LinkWithChannel';
import { useHeaderSideMenuStete } from './MenuStateProvider';

interface Props extends ComponentProps<typeof PageTitleWrapper> {
  ChannelLogo: ReactNode;
  wrapperClassName?: string;
}

export function HamburgerIcons({
  pageTitle,
  wrapperClassName,
  ChannelLogo,
}: Props) {
  const {
    isLanguageSelectionOpen,
    setIsLanguageSelectionOpen,
    isMenuOpen,
    isVisible,
    onMenuClick,
  } = useHeaderSideMenuStete();
  const { isMobile } = useDeviceType();

  const channelPref = useGetChannelPref();

  const { secondPath } = extractPathsFromAllPath(
    generateChannelPath(channelPref),
  );

  return (
    <div
      id="hamburger-menu"
      className={cn(
        'xl:min-w-[207px] sm:min-w-fit w-fit sm:start-0 end-4 flex flex-row gap-2 lg:justify-center justify-start items-center py-2.5 xl:px-10 sm:ps-8 ps-4 pe-4 bg-black sm:hover:bg-grey-11 z-20 sm:rounded-none rounded sm:max-w-none max-w-[calc(100%-32px)] cursor-pointer transition-all duration-300',
        {
          'lg:absolute sm:relative sm:bottom-[-1px] sm:top-[-1px] fixed bottom-16':
            !pageTitle && !isLanguageSelectionOpen,
          'sm:absolute sm:bottom-[-1px] sm:top-[-1px] fixed bottom-16':
            pageTitle,
          'sm:!relative !fixed sm:bottom-[-1px] bottom-16':
            isLanguageSelectionOpen,
          'xl:pe-[5px] xl:ps-10 pe-0': isVisible && !secondPath,
          'pe-4': isMobile && isMenuOpen,
        },
        wrapperClassName,
      )}
    >
      {/* Hamburger or Close Icon area */}
      <div
        onClick={() => onMenuClick()}
        className="w-8 h-8 flex items-center justify-center cursor-pointer z-10"
      >
        {isMenuOpen ? (
          <CloseIcon
            className="cursor-pointer"
            width={18}
            height={18}
            color="white"
          />
        ) : (
          <HamburgerMenuIcon
            className="cursor-pointer"
            width={32}
            height={32}
            color="white"
          />
        )}
      </div>
      {/* We will show logo with TRT if header is sticky otherwise we will show logo without TRT */}
      <LinkWithChannelLink
        href={'/'}
        className={cn(
          'text-white cursor-pointer font-sf uppercase font-bold h-full group transition-all ease-out duration-120',
          {
            'sm:opacity-100 sm:w-auto opacity-0 w-0 sm:static absolute':
              isMenuOpen,
          },
        )}
      >
        {ChannelLogo}
      </LinkWithChannelLink>
      {secondPath && (
        <LanguageSelectionWrapper
          isLanguageSelectionOpen={isLanguageSelectionOpen}
          setIsLanguageSelectionOpen={setIsLanguageSelectionOpen}
          isMainLogoInView={isVisible}
        />
      )}
      {!isMenuOpen && secondPath && <LanguageSelectionMobileWrapper />}
    </div>
  );
}

import { cn } from '@/lib/utils';
import { LocaleUppercase } from '@/app/components/text/LocaleUppercase';
import React from 'react';

type Props = {
  pageTitle?: string;
  parentTitle?: string;
  titleClassName?: string;
  parentTitleClassName?: string;
  className?: string;
};
export function PageTitleWrapper({
  className,
  pageTitle,
  titleClassName,
  parentTitle,
  parentTitleClassName,
}: Props) {
  return (
    <div className={cn('flex flex-col justify-center items-center', className)}>
      {parentTitle ? (
        <p
          className={cn(
            'font-notosans text-sm text-grey-8',
            parentTitleClassName,
          )}
        >
          <LocaleUppercase>{parentTitle}</LocaleUppercase>
        </p>
      ) : null}
      <div
        className={cn(
          'text-opinion-gold font-playfair xl:text-4.5xl md:text-4xl lg:text-3.5xl text-2xl leading-9 font-bold uppercase',
          titleClassName,
        )}
      >
        {/* topic should be to locale upper case to fix the 'TÜRKİYE' header issue instead of TÜRKIYE*/}
        <LocaleUppercase>{pageTitle}</LocaleUppercase>
      </div>
    </div>
  );
}
